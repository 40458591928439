<template>
  <div class="justify-content-md-center">
    <div id="st-notification-frame"></div>
    <form id="st-form" name="form" :action="action" method="POST"></form>
    <div class="center-screen">
      <div v-if="loading">
        <div class="loader">Loading...</div>
        <h4>Processing... Please wait</h4>
      </div>
    </div>
  </div>
</template>
 
<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: true,
      action:
        "https://payments.abmoneyplus.com/order/payments/moto/app-payment-finish.php",

      dataLog: {
        action: "",
        bankholderid: "",
        currency: "",
        feepay: "",
        plusuid: "",
        rate: "",
        topup: "",
        total: "",
        routerrate: "",
        surcharge: "",
        countryCode: "",
        cardType: "",
        cardCurrency: "",
        rememberCard: false,
        last3digi: "",
        cardNetwork: "",
        expireDate: "",
      },
    };
  },
  methods: {
    saveLog(data) {
      let jsonData = {
        baseamount: data["baseamount"],
        currencyiso3a: data["currencyiso3a"],
        enrolled: data["enrolled"],
        errorcode: data["errorcode"],
        errormessage: data["errormessage"],
        orderreference: data["orderreference"],
        settlestatus: data["settlestatus"],
        status: data["status"],
        transactionreference: data["transactionreference"],
        action: this.dataLog.action,
        bankholderid: this.dataLog.bankholderid,
        currency: this.dataLog.currency,
        feepay: this.dataLog.feepay,
        jwt: this.dataLog.jwt,
        plusuid: this.dataLog.plusuid,
        currency: this.dataLog.currency,
        feepay: this.dataLog.feepay,
        rate: this.dataLog.rate,
        topup: this.dataLog.topup,
        total: this.dataLog.total,
        routerrate: this.dataLog.routerrate,
        surcharge: this.dataLog.surcharge,
        countryCode: this.dataLog.countryCode,
        cardType: this.dataLog.cardType,
        cardCurrency: this.dataLog.cardCurrency,
        rememberCard: this.dataLog.rememberCard,
        last3digi: this.dataLog.last3digi,
        cardNetwork: this.dataLog.cardNetwork,
        expireDate: this.dataLog.expireDate,
      };

      const option = {
        method: "POST",
        data: JSON.stringify(jsonData),
        url: this.action,
      };

      axios(option).then((res) => {
        if (jsonData.errorcode == "0" && jsonData.errormessage) {
          this.$swal(
            `Payment`,
            `has been successfully processed`,
            "success"
          ).then(() => {
            let receipttoken = res['data']['receipttoken'];
            let query = receipttoken ? `?receipttoken=${receipttoken}` : "";
            location.href = "/payment-topup-success" + query;
          });
        } else {
          this.$swal(
            "Code:" + jsonData.errorcode,
            jsonData.errormessage,
            "error"
          );
        }
      });
    },
    mapData(data) {
      this.dataLog.action = data.action;
      this.dataLog.bankholderid = data.bankholderid;
      this.dataLog.currency = data.currency;
      this.dataLog.feepay = data.feepay;
      this.dataLog.jwt = data.jwt;
      this.dataLog.plusuid = data.plusuid;
      this.dataLog.currency = data.currency;
      this.dataLog.feepay = data.feepay;
      this.dataLog.rate = data.rate;
      this.dataLog.topup = data.topup;
      this.dataLog.total = data.total;
      this.dataLog.routerrate = data.routerrate;
      this.dataLog.surcharge = data.surcharge;
      this.dataLog.countryCode = data.countryCode;
      this.dataLog.cardType = data.cardType;
      this.dataLog.cardCurrency = data.cardCurrency;
      this.dataLog.rememberCard = data.rememberCard == 'true';
      this.dataLog.last3digi = data.last3digi;
      this.dataLog.cardNetwork = data.cardNetwork;
      this.dataLog.expireDate = data.expireDate;
      //console.log(this.dataLog);
      setTimeout(() => {
        this.tradingRun();
      }, 3000);
    },
    tradingRun() {
      //console.log(this.dataLog)
      var st = SecureTrading({
        livestatus: process.env.NODE_ENV == "production" ? 1 : 0,
        jwt: this.dataLog.jwt,
        submitOnError: false,
        submitOnCancel: false,
        submitOnSuccess: false,
        submitCallback: async (data) => {
          this.loading = false;
          this.saveLog(data);
          //
        },
      });

      st.Components({ startOnLoad: true });

      st.on("success", async () => {
        this.loading = false;
      });

      st.on("error", async () => {
        this.loading = false;
        setTimeout(() => {
          location.href = "/payment-topup-error";
        }, 3000);

      });
    },
  },
  mounted() {
    if (document.getElementById("secure-trading")) return; // was already loaded
    var scriptTag = document.createElement("script");
    scriptTag.src = "https://webservices.securetrading.net/js/v3/st.js";
    scriptTag.id = "secure-trading";
    document.getElementsByTagName("body")[0].appendChild(scriptTag);
    this.mapData(this.$route.query);
  },
};
</script>

<style lang="scss">
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  .loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(
      left,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
      left,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
      left,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
      left,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
      to right,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: #11cdef;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  .loader:after {
    background: #f5f7fa;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>